
import { defineComponent, ref } from 'vue'
import {
    DeleteOutlined,
    QuestionCircleOutlined,
    EditOutlined,
    UserAddOutlined,
    LockOutlined
} from '@ant-design/icons-vue'
import { UserService } from '@/services/UserService'
import { notification } from 'ant-design-vue'
import { FolderService } from '@/services/FolderService'
import { FolderWithUsersResponse } from '@/services/responses/FolderWithUsersResponse'
import { AddUsersInFolderRequest } from '@/services/requests/AddUsersInFolderRequest'
import { UpdateFolderRequest } from '@/services/requests/UpdateFolderRequest'

export default defineComponent({
    name: 'VaultsConfiguration',
    components: {
        LockOutlined,
        UserAddOutlined,
        EditOutlined,
        DeleteOutlined,
        QuestionCircleOutlined
    },
    data: () => ({
        lengthTitle: 50,
        open: ref<boolean>(false),
        loading: ref<boolean>(false),
        option: ref<number>(1),
        addUserInVault: ref<AddUsersInFolderRequest>({
            folderId: 0,
            userIds: []
        }),
        editFolder: ref<UpdateFolderRequest>(
            {
                id: 0,
                name: ''
            }
        ),
        users: [],
        usersSelect: [],
        columns: [
            {
                title: 'Nome',
                dataIndex: 'name',
                key: 'name',
                slots: { customRender: 'name' },
                fixed: 'center',
                resizable: true,
                width: 15
            },
            {
                title: 'Usuários',
                dataIndex: 'users',
                key: 'users',
                slots: { customRender: 'users' },
                width: 15
            },
            {
                title: '',
                key: 'action',
                slots: { customRender: 'action' },
                width: 5
            }
        ],
        folders: [] as FolderWithUsersResponse[],
        userService: null as any,
        folderService: null as any,
        formCreateValues: {
            name: '' as string
        }
    }),
    async created () {
        this.userService = UserService.getInstance()
        const users = await this.userService.getUsers(true)
        this.users = users.map((x:any) => ({ label: x.name, value: x.id }))
        this.folderService = FolderService.getInstance()
        await this.loadFolders()
    },
    computed: {
        getFolders () {
            return this.folders
        }
    },
    methods: {
        titleModal () {
            return this.option === 1 ? 'Selecionar usuários' : 'Alterar nome do cofre'
        },

        validTitle (title: string) {
            if (title.length > this.lengthTitle) {
                notification.warning({
                message: 'Nome de cofre inválido',
                description: `O nome do cofre deve conter até ${this.lengthTitle} caracteres.`
                })
            }
        },

        async loadFolders () {
            this.folders = await this.folderService.getListFolderWithUser()
        },

        openAddRemoveUsers (folder:any) {
            this.addUserInVault.folderId = folder.id
            this.addUserInVault.userIds = folder.users.map((x:any) => x.id)
            this.option = 1
            this.open = true
        },

        openEditVault (folder:any) {
            this.editFolder.id = folder.id
            this.editFolder.name = folder.name
            this.option = 2
            this.open = true
        },

        handleResizeColumn (w: any, col: any) {
            col.width = w
        },

        async createVault () {
            try {
                const vault = this.formCreateValues.name
                if (vault.trim().length > 0) {
                    await this.folderService.createfolder(this.formCreateValues.name)
                    await this.loadFolders()
                    this.formCreateValues.name = ''
                } else {
                    notification.warning({
                        message: 'Falha criar Cofre',
                        description: 'Para criar um cofre preencha com um nome apropriado!'
                    })
                }
            } catch (err) {
                notification.error({
                    message: 'Falha ao criar Cofre',
                    description: 'Erro inesperado'
                })
            }
        },

        async handleOk () {
            this.loading = true
            if (this.option === 1) {
                try {
                    this.folderService.addUserInVault(this.addUserInVault)
                    this.open = false
                    this.addUserInVault = {
                        folderId: 0,
                        userIds: []
                    }
                } catch (err) {
                    notification.error({
                        message: 'Falha ao alterar usuários do cofre',
                        description: 'Erro inesperado'
                    })
                }
            } else {
                try {
                    this.editFolder.name = this.editFolder.name.trim()
                    await this.folderService.updatefolder(this.editFolder)
                    this.open = false
                    this.editFolder = {
                        id: 0,
                        name: ''
                    }
                } catch (err) {
                    notification.error({
                        message: 'Falha ao alterar cofre',
                        description: 'Erro inesperado'
                    })
                }
            }

            this.loading = false
            await this.loadFolders()
        },

        async remove (folder:any) {
            try {
                await this.folderService.deleteFolder(folder.id)
                await this.loadFolders()
            } catch (err) {
                notification.error({
                    message: `Falha ao remover o cofre ${folder.name}`,
                    description: 'Erro inesperado'
                })
            }
        },

        handleCancel () {
            this.open = false
        }
    }
})
