import { HTTPBaseService } from './HTTPBaseService'
import { PlanPartnersResponse } from './responses/PlanPartnersResponse'

export class PartnerService extends HTTPBaseService {
    private static classInstance?: PartnerService

    constructor () {
      super(process.env.VUE_APP_ROOT_API_SHAR)
    }

    public static getInstance () {
      if (!this.classInstance) {
        this.classInstance = new PartnerService()
      }
      return this.classInstance
    }

    public async getPartnerData (accountId: number) : Promise<any> {
        const { data } = await this.instance.get<any>(
          '/partner/api/v1/partner/' + accountId
        )
        return data
    }

    public async getPartnerCustomers (
      partnerHash: string,
      pageNumber: number,
      pageSize: number | null,
      order: string | null,
      filters: string | null
    ): Promise<any> {
      const { data } = await this.instance.get<any>(
        '/partner/api/v1/partner/customers',
        { params: { partnerHash, pageNumber, pageSize, order, filters } }
      )
      return data
    }

    public async getPlansPartner (
      partnerHash : string | null | undefined
    ) :Promise<PlanPartnersResponse[]> {
      const { data } = await this.instance.get<PlanPartnersResponse[]>(
        '/partner/api/v1/plans',
        { params: { partnerHash } }
      )
      return data
    }

    public async searchPartnerCustomers (
        partnerHash: string | null,
        pageNumber: number,
        pageSize: number | null,
        term: string
      ): Promise<any> {
        const { data } = await this.instance.get<any>(
          '/partner/api/v1/partner/customers-search',
          { params: { partnerHash, pageNumber, pageSize, term } }
        )
        return data
      }
}
