import { AxiosInstance, AxiosRequestConfig } from 'axios'
import { HTTPBaseService } from './HTTPBaseService'
import * as qs from 'qs'
export class PaperSignatureService extends HTTPBaseService {
  private static classInstance?: PaperSignatureService;

  constructor () {
    super(process.env.VUE_APP_ROOT_API_SHAR)
  }

  public static getInstance () {
    if (!this.classInstance) {
      this.classInstance = new PaperSignatureService()
    }

    return this.classInstance
  }

  public async listPapersSignatureRequest () : Promise<any> {
    const { data } = await this.instance.get<any>(
      '/core/api/v1/papers'
    )
    return data
  }
}
