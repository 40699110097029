
import { defineComponent, reactive, ref, UnwrapRef } from 'vue'
import { LoginService } from '@/services/LoginService'
import { LoginCommandRequest } from '@/services/requests/LoginCommandRequest'
import { TokenService } from '@/services/TokenService'
import { mapState, mapActions } from 'vuex'
import Footer from '@/assets/components/Footer.vue'

interface formLogin {
  email: string | null
  password: string | null
  checked: boolean
}

export default defineComponent({
  name: 'AuthenticatorLogin',
  components: {
    Footer
  },
  data: () => ({
    formLogin: reactive<formLogin>({
      email: null,
      password: null,
      checked: false
    }) as UnwrapRef<formLogin>,
    rules: {
      email: [
        { required: true, message: 'Por favor insira o email', trigger: 'blur' }
      ]
    },
    show: false,
    items: [
      {
        label: 'Para mim',
        path: 'pessoaFisica'
      },
      {
        label: 'Para meu negocio',
        path: 'pessoaJuridica'
      }
    ],
    geolocation: '' as string | null,
    loginService: null as LoginService | null,
    showModal: false as boolean,
    opcao: null as any,
    showAlert: false,
    formRef: ref(),
    isMobile: ref<boolean>(false),
    version: ref<any>(process.env.VUE_APP_VERSION)
  }),

  mounted () {
    if (window.innerWidth < 768) {
      this.isMobile = true
    } else {
      this.isMobile = false
    }
    const token = TokenService.getToken()
    if (token !== '') this.$router.push({ name: 'home' })
    this.loginService = LoginService.getInstance()
    this.loginService.getLocation(this.setPosition)
  },

  watch: {
    opcao () {
      if (this.opcao != null) {
        this.showAlert = false
      }
    }
  },

  computed: {
    ...mapState('authenticator', ['showModalState'])
  },

  methods: {
    ...mapActions('authenticator', [
      'setEmailUsuario',
      'setAdmin',
      'setLogo',
      'setUserId',
      'setAccountId'
    ]),
    setPosition (position: any) {
      if (position) {
        this.geolocation = `${position.coords.latitude},${position.coords.longitude}`
      } else this.geolocation = ''
    },
    async login () {
      const request: LoginCommandRequest = {
        email: this.formLogin.email!,
        password: btoa(this.formLogin.password!),
        geolocalization: this.geolocation || '',
        notificationKey: null
      }
      try {
        const result = await this.loginService?.login(request)
        TokenService.setToken(result!.token)
        TokenService.setUserData({
          accountId: result!.accountId,
          userId: result!.userId,
          userEmail: result!.userEmail,
          logo: result!.logo
        })

        this.setUserId(result!.userId)
        this.setAccountId(result!.accountId)
        this.setAdmin(result?.isAdmin)
        this.setEmailUsuario(result!.userEmail)
        this.setLogo(result!.logo)
        this.$router.push({
          name: 'home'
        })
      } catch (err) {
        TokenService.clear()
      }
    },
    redirect () {
      if (this.opcao == null) {
        this.showAlert = true
      } else {
        this.showAlert = false
        this.$router.push({
          name: this.opcao?.path
        })
        this.showAlert = false
        this.showModal = false
      }
    },
    createAccount () {
      this.$router.push({
        name: 'cadastro'
      })
    }
  }
})
