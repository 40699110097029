import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-72e40594"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "navigation" }
const _hoisted_2 = { class: "column-ic" }
const _hoisted_3 = { key: 0 }
const _hoisted_4 = { class: "column-ic" }
const _hoisted_5 = { key: 0 }
const _hoisted_6 = { class: "column-ic" }
const _hoisted_7 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_HomeOutlined = _resolveComponent("HomeOutlined")!
  const _component_FileProtectOutlined = _resolveComponent("FileProtectOutlined")!
  const _component_ExceptionOutlined = _resolveComponent("ExceptionOutlined")!

  return (_openBlock(), _createElementBlock("nav", _hoisted_1, [
    _createElementVNode("section", {
      class: _normalizeClass(["navigation-column", {'active' : _ctx.isHomeActive}]),
      onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.getHomePage && _ctx.getHomePage(...args)))
    }, [
      _createElementVNode("span", _hoisted_2, [
        _createVNode(_component_HomeOutlined)
      ]),
      (!_ctx.collapsed)
        ? (_openBlock(), _createElementBlock("span", _hoisted_3, "Página Inicial"))
        : _createCommentVNode("", true)
    ], 2),
    _createElementVNode("section", {
      class: _normalizeClass(["navigation-column", {'active' : _ctx.isSignedActive}]),
      onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.getEnvelopesByStatus(2)))
    }, [
      _createElementVNode("span", _hoisted_4, [
        _createVNode(_component_FileProtectOutlined)
      ]),
      (!_ctx.collapsed)
        ? (_openBlock(), _createElementBlock("span", _hoisted_5, "Documentos Assinados"))
        : _createCommentVNode("", true)
    ], 2),
    _createElementVNode("section", {
      class: _normalizeClass(["navigation-column", {'active' : _ctx.isCanceledActive}]),
      onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.getEnvelopesByStatus(3)))
    }, [
      _createElementVNode("span", _hoisted_6, [
        _createVNode(_component_ExceptionOutlined)
      ]),
      (!_ctx.collapsed)
        ? (_openBlock(), _createElementBlock("span", _hoisted_7, "Documentos Cancelados"))
        : _createCommentVNode("", true)
    ], 2)
  ]))
}