import { HTTPBaseService } from './HTTPBaseService'

export class ScheduleService extends HTTPBaseService {
  private static classInstance?: ScheduleService

  constructor () {
    super(process.env.VUE_APP_ROOT_API_SHAR)
  }

  public static getInstance () {
    if (!this.classInstance) {
      this.classInstance = new ScheduleService()
    }
    return this.classInstance
  }

  public async getSchedules (): Promise<any> {
    const { data } = await this.instance.get<any>(
      '/core/api/v1/schedules'
    )

    return data
  }

  public async getScheduleFavorites (userId: number): Promise<any> {
    const { data } = await this.instance.get<any>(
      '/core/api/v1/schedules/favorites',
      { params: { userId } }
    )

    return data
  }

  public async createScheduleContact (scheduleContact: any): Promise<any> {
    const { data } = await this.instance.post<any>('/core/api/v1/schedules', scheduleContact)
    return data
  }

  public async updateScheduleContact (scheduleContact: any): Promise<any> {
    const { data } = await this.instance.put<any>('/core/api/v1/schedules', scheduleContact)
    return data
  }

  public async removeSchedule (userId: number, scheduleId: number): Promise<void> {
    await this.instance.put(`/core/api/v1/schedules/remove?userId=${userId}&scheduleId=${scheduleId}`)
  }

  public async favoriteSchedule (userId: number, scheduleId: number): Promise<any> {
    const { data } = await this.instance.post<any>(`/core/api/v1/schedules/favorite?userId=${userId}&scheduleId=${scheduleId}`)
    return data
  }

  public async removeFavoriteSchedule (userId: number, scheduleId: number): Promise<void> {
    await this.instance.delete(`/core/api/v1/schedules/favorite?userId=${userId}&scheduleId=${scheduleId}`)
  }
}
