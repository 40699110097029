
  import { defineComponent, reactive, ref, UnwrapRef } from 'vue'
  import { LoginService } from '@/services/LoginService'
  import { AccountService } from '@/services/AccountService'
  import { UserService } from '@/services/UserService'
  import { LoginCommandRequest } from '@/services/requests/LoginCommandRequest'
  import { CreateAccountTestABCommandRequest } from '@/services/requests/CreateAccountTestABCommandRequest'
  import { TokenService } from '@/services/TokenService'
  import { mapState, mapActions } from 'vuex'
  import {
    CheckCircleFilled
  } from '@ant-design/icons-vue'
  import { notification } from 'ant-design-vue'

  import ModalTerms from '../modal/modalTerms.vue'
  import ModalPolicy from '../modal/modalPolicy.vue'
  import Footer from '@/assets/components/Footer.vue'

  enum ETypeAccount {
    DEFAULT = 0,
    INDIVIDUAL = 1,
    COMPANY = 2
  }
  interface FormLogin {
    email: string | null
    password: string | null
    checked: boolean
  }
  interface FormAccount {
    name: string | null
    email: string | null
    cpf: string | null
    password: string | null
  }

  export default defineComponent({
    name: 'CreateAccount',
    components: {
      ModalTerms,
      ModalPolicy,
      CheckCircleFilled,
      Footer
    },
    data: () => ({
      key: '' as string | null | undefined,
      isFilledTerms: ref<boolean>(false),
      formLogin: reactive<FormLogin>({
        email: null,
        password: null,
        checked: false
      }) as UnwrapRef<FormLogin>,

      formAccount: reactive<FormAccount>({
        name: null as string | null,
        email: null as string | null,
        cpf: null as string | null,
        password: null as string | null
      }) as UnwrapRef<FormAccount>,
      confirmEmail: null as string | null,
      rules: {
        email: [
          { required: true, message: 'Por favor insira o e-mail', trigger: 'blur' }
        ]
      },
      isMobile: ref<boolean>(false),
      isCreateAccount: ref<boolean>(false),
      isSuccessCreateAccount: ref<boolean>(false),
      isSuccessVerifyUser: ref<boolean>(false),
      typeAccount: ETypeAccount.DEFAULT as ETypeAccount,
      geolocation: '' as string | null,
      loginService: null as LoginService | null,
      accountService: null as AccountService | null,
      userService: null as UserService | null,
      visible: ref<boolean>(false),
      textVisible: ref<number>(0),
      formRef: ref(),
      partner: '' as string | null | undefined,
      partnerHash: '' as string | null | undefined,
      clientHash: '' as string | null | undefined,
      isPartner: ref<boolean>(false),
      version: ref<any>(process.env.VUE_APP_VERSION)
    }),

    async mounted () {
      if (window.innerWidth < 768) {
        this.isMobile = true
      } else {
        this.isMobile = false
      }
      this.isCreateAccount = true
      this.isSuccessCreateAccount = false
      this.isSuccessVerifyUser = false
      this.key = null

      const token = TokenService.getToken()
      if (token !== '') this.$router.replace('/home')

      // Validate account
      this.userService = UserService.getInstance()
      this.key = this.$route.query.key?.toString()
      if (this.key) {
        TokenService.setToken(this.key!)
        await this.verifyUser()
      }

      // Create account campaign
      const isTestAB = this.$route.query.campaign?.toString()
      if (isTestAB) {
        this.isCreateAccount = true
      }

      // Create account partner
      this.isPartner = false
      this.partner = this.$route.query.partner?.toString()
      this.partnerHash = this.$route.query.partnerHash?.toString()
      this.clientHash = this.$route.query.clientHash?.toString()
      if (this.partner && this.partnerHash && this.clientHash) {
        this.isPartner = true
      }

      this.accountService = AccountService.getInstance()
      this.loginService = LoginService.getInstance()
      this.loginService.getLocation(this.setPosition)
    },

    computed: {
      ...mapState('authenticator', ['showModalState'])
    },

    methods: {
      ...mapActions('authenticator', [
        'setEmailUsuario',
        'setAdmin',
        'setLogo',
        'setUserId',
        'setAccountId'
      ]),
      setPosition (position: any) {
        if (position) {
          this.geolocation = `${position.coords.latitude},${position.coords.longitude}`
        } else this.geolocation = ''
      },
      checkIsFilled () {
        let check = true
        Object.values(this.formAccount).forEach((item) => {
          if (!item) {
            check = false
          }
        })
        return check
      },
      AcceptTerms () {
        this.isFilledTerms = !this.isFilledTerms
      },
      setCreateAccount () {
        this.isCreateAccount = true
      },
      setLoginAccount () {
        this.isCreateAccount = false
        this.isSuccessCreateAccount = false
        this.isSuccessVerifyUser = false
        this.$router.replace('/login')
      },
      showModal (number: number) {
        this.textVisible = number
        this.visible = true
      },
      handleOk (e: MouseEvent) {
        this.visible = false
      },
      async login () {
        const request: LoginCommandRequest = {
          email: this.formLogin.email!,
          password: this.formLogin.password!,
          geolocalization: this.geolocation || '',
          notificationKey: null
        }
        const checkIsFilled: boolean = (this.formLogin.email === null || this.formLogin.password === null)
        if (checkIsFilled) {
          notification.error({
            message: 'Falha no login',
            description: 'Os Campos: E-mail e Senha são obrigatórios e precisam ser preenchidos!'
          })
        } else {
          try {
            const result = await this.loginService?.login(request)
            TokenService.setToken(result!.token)
            TokenService.setUserData({
              accountId: result!.accountId,
              userId: result!.userId,
              userEmail: result!.userEmail,
              logo: result!.logo
            })

            this.setUserId(result!.userId)
            this.setAccountId(result!.accountId)
            this.setAdmin(result?.isAdmin)
            this.setEmailUsuario(result!.userEmail)
            this.setLogo(result!.logo)
            this.$router.push({
              name: 'home'
            })
          } catch (err) {
            TokenService.clear()
          }
        }
      },
      async createAccount () {
        const isFilled = this.checkIsFilled()
        if (isFilled) {
          if (this.formAccount.email === this.confirmEmail) {
            if (this.typeAccount !== ETypeAccount.DEFAULT) {
              if (this.isFilledTerms) {
                try {
                  const request: CreateAccountTestABCommandRequest = {
                    name: this.formAccount.name,
                    email: this.formAccount.email,
                    cpf: this.formAccount.cpf,
                    password: this.formAccount.password,
                    type: this.typeAccount.toString(),
                    term: '',
                    geolocalization: '',
                    partner: (this.partner !== undefined) ? this.partner : null,
                    partnerHash: (this.partnerHash !== undefined) ? this.partnerHash : null,
                    partnerClientHash: (this.clientHash !== undefined) ? this.clientHash : null,
                    isPartner: this.isPartner
                  }

                  const res = await this.accountService?.createAccountTestAB(request)
                  if (res) {
                    this.isCreateAccount = false
                    this.isSuccessCreateAccount = true
                    this.isSuccessVerifyUser = false
                    if (this.isPartner) {
                      // redirect to gateway
                      const dataForGatewayPlanPartner = {
                        reference: 1,
                        id: res.id,
                        name: res.name,
                        cpfCnpj: '',
                        domain: '',
                        phone: '',
                        address: '',
                        addressNumber: '',
                        zipCode: '',
                        city: '',
                        state: '',
                        district: '',
                        contact: '',
                        planName: '',
                        productToken: process.env.VUE_APP_ROOT_PRODUCT_TOKEN,
                        productUrl: process.env.VUE_APP_ROOT_PRODUCT_URL,
                        isPartner: this.isPartner,
                        partnerId: res.partnerId
                      }

                      const dataForGatewayPlanPartnerEncode = btoa(JSON.stringify(dataForGatewayPlanPartner))
                      window.location.href = process.env.VUE_APP_ROOT_GATEWAY_URL + '/plans?data=' + dataForGatewayPlanPartnerEncode
                    }
                  } else {
                    notification.error({
                      message: 'Falha no Cadastro',
                      description: 'Não foi possivel criar sua conta!'
                    })
                  }
                } catch (err) {
                  console.log(err)
                }
              } else {
                notification.error({
                  message: 'Falha no Cadastro',
                  description: 'Você precisa concordar com os Termos de uso!'
                })
              }
            } else {
              notification.error({
                message: 'Falha no Cadastro',
                description: 'Você precisa selecionar um tipo de conta!'
              })
            }
          } else {
            notification.error({
              message: 'Falha no Cadastro',
              description: 'Os dados dos campos: E-mail e Confirmação de e-mail não conferem!'
            })
          }
        } else {
          notification.error({
            message: 'Falha no Cadastro',
            description: 'Os Campos: Nome, E-mail, Senha e CPF são obrigatórios e precisam ser preenchidos!'
          })
        }
      },
      async verifyUser () {
        try {
          await this.userService?.verify()
          this.isSuccessVerifyUser = true
        } catch (e) {
          this.isSuccessVerifyUser = false
        }
        TokenService.clear()
      }
    }
  })
