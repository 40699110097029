import { HTTPBaseService } from './HTTPBaseService'

export class IntegrationService extends HTTPBaseService {
    private static classInstance?: IntegrationService

    constructor () {
      super(process.env.VUE_APP_ROOT_API_SHAR_INTEGRATION)
    }

    public static getInstance () {
      if (!this.classInstance) {
        this.classInstance = new IntegrationService()
      }
      return this.classInstance
    }

    public async getIntegrationPartnerData (
       partnerHash: any
    ) : Promise<any> {
        const { data } = await this.instance.get<any>(
            `/api/v1/partners/data-account/${partnerHash}`,
            { headers: { 'x-api-key': process.env.VUE_APP_ROOT_X_API_KEY } }
        )
        return data
    }

    public async getIntegrationPartnerClientData (
        partnerId: any,
        clientId: any
    ) : Promise<any> {
        const { data } = await this.instance.get<any>(
            '/api/v1/products/data-client',
            {
                params: { partnerId, clientId },
                headers: { 'x-api-key': process.env.VUE_APP_ROOT_X_API_KEY }
            }
        )
        return data
    }
}
