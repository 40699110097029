import { AxiosInstance, AxiosRequestConfig } from 'axios'
import { HTTPBaseService } from './HTTPBaseService'
import * as qs from 'qs'
import { LinkActiveUserRequest } from './requests/LinkActiveUserRequest'
export class RedirectService extends HTTPBaseService {
  private static classInstance?: RedirectService;

  constructor () {
    super(process.env.VUE_APP_ROOT_API_SHAR)
  }

  public static getInstance () {
    if (!this.classInstance) {
      this.classInstance = new RedirectService()
    }

    return this.classInstance
  }

  public async getLinkToActiveUser (
      request: LinkActiveUserRequest
    ) : Promise<any> {
    const { data } = await this.instance.post<any>(
      '/redirect/api/v1/redirectlinks/link-active',
      request
    )

    return data
  }
}
