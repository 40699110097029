import { HTTPBaseService } from './HTTPBaseService'

export class SignatureService extends HTTPBaseService {
  private static classInstance?: SignatureService

  constructor () {
    super(process.env.VUE_APP_ROOT_API_SHAR)
  }

  public static getInstance () {
    if (!this.classInstance) {
      this.classInstance = new SignatureService()
    }
    return this.classInstance
  }

  public async resendNotificationRecipient (
    formData: any
  ): Promise<void> {
    await this.instance.post<any>('/core/api/v1/signature/resend', formData)
  }

  public async getLinkSignatureRecipient (
    formData: any
  ): Promise<any> {
    const { data } = await this.instance.post<any>('/core/api/v1/signature/link', formData)
    return data
  }
}
